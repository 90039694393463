body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.register-link {
  padding-top: 15px;
  font-size: 13px;
  text-decoration: none;
  color: grey;
}

.register-link:hover {
  text-decoration: underline;
}

.MuiIconButton-colorInherit .MuiIconButton-label {
  color: #6c757d;
}

.MuiTable-root td , th {
  white-space: nowrap;
}

.rbc-agenda-content {
  height: 270px;
  overflow-y: scroll;
}

#responsability-matrix {
  width: 100%;
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;
}

#responsability-matrix th {
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  padding: 6px;
  /* border-collapse: separate; */
  min-width: 200px;
  max-width: 200px;
}

#responsability-matrix td {
  padding: 6px;
  border-bottom: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  min-width: 200px;
  max-width: 200px;
}


#responsability-matrix th:first-child,
#responsability-matrix td:first-child {
  /* Apply a left border on the first <td> or <th> in a row */
  border-left: 1px solid lightgrey;
}

#revenue-planning {
  cursor: cell;
  width: 100%;
  border-spacing: 0;
}

#revenue-planning th {
  border: .5px solid lightgrey;
  padding: 10px;
  border-collapse: separate;
  font-weight: bold;
}

#revenue-planning td {
  padding: 10px;
  border: 1px solid lightgrey;
}

.MuiDrawer-paperAnchorLeft::-webkit-scrollbar {
  display: none;
}


.PrivateHiddenCss-xsDown-3 {
  display: block !important;
}

main {
  max-width: calc(100% - 59px) !important;
  overflow-y: auto;
}

.header-actions-buttons {
  max-width: 100% !important;
}

.mobile-horizontal-table {
}

@media only screen and (max-width: 1080px) {
  main {
    max-width: 100% !important;
    overflow-y: auto;
  }

  .header-actions-buttons {
    max-width: 100% !important;
    flex-direction: column !important;
    justify-content: center;
    align-items: flex-start;
    padding: 5px;
    height: auto !important;
  }

  .mobile-horizontal-table {
    flex-direction: column !important;
    width: 100% !important;
    height: auto;
    margin: 0px !important;
    padding: 0px !important;
  }

  .MuiPaper-rounded {
    max-width: 100% !important;
    width: 100% !important;
  }

  .MuiMenu-paper {
    min-height: 52vh !important;
    height: auto !important;
    left: 0px !important;
  }
}

.MuiPaper-rounded {
  max-width: 100% !important;
}

#budget-summary-table {
  width: fit-content;
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;
}

#budget-summary-table th {
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  padding: 6px;
}

#budget-summary-table td {
  padding: 6px;
  border-bottom: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
}

#budget-summary-table th:first-child,
#budget-summary-table td:first-child {
  /* Apply a left border on the first <td> or <th> in a row */
  border-left: 1px solid lightgrey;
}

#action-plan-tasks-table {
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;
}

#action-plan-tasks-table th {
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  padding: 6px;
  text-align: center;
}

#action-plan-tasks-table td {
  padding: 6px;
  border-bottom: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
}

#action-plan-tasks-table th:first-child,
#action-plan-tasks-table td:first-child {
  /* Apply a left border on the first <td> or <th> in a row */
  border-left: 1px solid lightgrey;
}

.MuiTable-root:not(#budget-summary-table):not(#responsability-matrix):not(#excel-preview-table):not(#action-plan-tasks-table):not(#revenue-planning) .MuiTableCell-head:nth-child(3) {
  position: sticky;
  left: 0;
  z-index: 999;
  background-color: white;
}

.MuiTable-root:not(#budget-summary-table):not(#responsability-matrix):not(#excel-preview-table):not(#action-plan-tasks-table):not(#revenue-planning) .MuiTableCell-body:nth-child(3) {
  position: sticky;
  left: 0;
  /* z-index: 999; */
  background-color: white;
}

.MuiTable-root:not(#budget-summary-table):not(#responsability-matrix):not(#excel-preview-table):not(#action-plan-tasks-table):not(#revenue-planning) .MuiTableRow-hover td {
  background-color: white !important;
}

.drag-line {
  border-color: grey !important;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.category-drag-line {
  border-color: #B30000 !important;
  border-width: 5px !important;
}

.pac-container {
  z-index: 9999 !important;
}

.MuiSlider-root {
  color: #D90000 !important;
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
  border-left: 1px solid #bbb;
}

.comments-textarea__control{
  /* margin-top: 10px;
  margin-bottom: 20px; */
}
.comments-textarea__input{
  padding: 10px;
  background-color: #fff;
  border-radius: 3px;
  transition: 0.3s ease-in-out;
  font-size: 14px;
  color: #333;
  border: 1px solid #dedede;
  &:focus,&:active{
    outline: none;
    border: 1px solid #b3b3b3;
  }
  &::placeholder {
    color: #a4b0be;
    font-size: 14px;
  }
}
.comments-textarea__suggestions__list {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 14px;
  max-height: 200px;
  overflow: auto;
}
.comments-textarea__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.comments-textarea__suggestions__item — focused {
  background-color: #daf4fa;
}
.comments-textarea__highlighter {
  padding: 10px;
}